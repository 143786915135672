<template>
  <section class="hero is-fullheight">
    <ModalEditProfile
      v-if="activeModalEditProfile"
      :active="activeModalEditProfile"
      :dataAdmin="dataAdmin"
      @close-modal="$emit('close-modal')"
      @successful-update-profile="successfulUpdateProfile"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <!-- Información general -->
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="columns is-multiline">
                  <div class="column is-4">
                    <label class="label">NOMBRE</label>
                    <p>
                      {{ dataAdmin.name }}
                    </p>
                  </div>
                  <div class="column is-4">
                    <label class="label">APELLIDOS</label>
                    <p>
                      {{ dataAdmin.lastName }}
                    </p>
                  </div>
                  <div class="column is-4">
                    <label class="label">CORREO ELECTRÓNICO</label>
                    <p>
                      {{ dataAdmin.email }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Contraseña -->
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="columns is-multiline is-vcentered">
                  <template v-if="!modeEditPassword">
                    <div class="column is-6">
                      <label class="label">Contraseña</label>
                      <p class="has-text-weight-bold">
                        *********
                      </p>
                    </div>
                    <div class="column is-6 has-text-right-desktop has-text-right-tablet">
                      <button class="button button_micro_dark_outlined" @click="modeEditPassword = true">
                        CAMBIAR CONTRASEÑA
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="column is-5 is-offset-1">
                      <form @submit.prevent="changePassword" class="columns is-multiline">
                        <div class="column is-12">
                          <label class="label" for="currentPassword">
                            Contraseña actual
                          </label>
                          <div class="field has-addons">
                            <div class="control is-fullwidth">
                              <input
                                id="currentPassword"
                                class="input icon_btn"
                                :type="inputTypeCurrent"
                                name="currentPassword"
                                placeholder="********"
                                v-model="currentPassword"
                                v-validate="'required'"
                                data-vv-as="de la contraseña actual"
                                autocomplete="autocomplete-none"
                              >
                            </div>
                            <div class="control has-icon">
                              <button class="button icon_action" type="button">
                                <i class="material-icons visibility_icon" @click="shPassword('current')">
                                  {{ inputTypeCurrent === 'password' ? 'visibility' : 'visibility_off' }}
                                </i>
                              </button>
                            </div>
                          </div>
                          <span v-show="vverrors.has('currentPassword')" class="help is-danger">{{ vverrors.first('currentPassword') }}</span>
                        </div>
                        <div class="column is-12">
                          <label class="label" for="password">
                            Contraseña nueva
                          </label>
                          <div class="field has-addons">
                            <div class="control is-fullwidth">
                              <input
                                id="password"
                                ref="password"
                                class="input icon_btn"
                                :type="inputType"
                                name="password"
                                placeholder="********"
                                v-model="password"
                                v-validate="'required'"
                                data-vv-as="de la contraseña nueva"
                                autocomplete="autocomplete-none"
                              >
                            </div>
                            <div class="control has-icon">
                              <button class="button icon_action" type="button">
                                <i class="material-icons visibility_icon" @click="shPassword()">
                                  {{ inputType === 'password' ? 'visibility' : 'visibility_off' }}
                                </i>
                              </button>
                            </div>
                          </div>
                          <span v-show="vverrors.has('password')" class="help is-danger">{{ vverrors.first('password') }}</span>
                        </div>
                        <div class="column is-12">
                          <label class="label" for="password">
                            Confirmar contraseña
                          </label>
                          <div class="field has-addons">
                            <div class="control is-fullwidth">
                              <input
                                id="password_confirmation"
                                class="input icon_btn"
                                :type="inputTypeConfirm"
                                v-model="passwordConfirmation"
                                name="password_confirmation"
                                placeholder="********"
                                v-validate="'required|confirmed:password'"
                                data-vv-as="de confirmar contraseña"
                                autocomplete="autocomplete-none"
                              >
                            </div>
                            <div class="control has-icon">
                              <button class="button icon_action" type="button">
                                <i class="material-icons visibility_icon" @click="shPassword('confirm')">
                                  {{ inputTypeConfirm === 'password' ? 'visibility' : 'visibility_off' }}
                                </i>
                              </button>
                            </div>
                          </div>
                          <span v-show="vverrors.has('password_confirmation')" class="help is-danger">{{ vverrors.first('password_confirmation') }}</span>
                        </div>
                        <div class="column is-6">
                          <div class="block has-text-centered">
                            <button
                              class="button button_micro_dark_outlined is-fullwidth"
                              :class="{ 'is-loading' : loading }"
                              :disabled="loading"
                              type="reset"
                              @click="modeEditPassword = false"
                            >
                              CANCELAR
                            </button>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div class="block has-text-centered">
                            <button
                              type="submit"
                              class="button button_micro_dark is-fullwidth"
                              :class="{ 'is-loading' : loading }"
                              :disabled="loading"
                            >
                              GUARDAR
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MyProfileMain',
  components: {
    ModalEditProfile: () => import('@/components/MyProfile/ModalEditProfile.vue')
  },
  data () {
    return {
      modeEditPassword: false,
      loading: false,
      currentPassword: null,
      password: null,
      passwordConfirmation: null,
      inputType: 'password',
      inputTypeConfirm: 'password',
      inputTypeCurrent: 'password',
      dataAdmin: {
        name: null,
        lastName: null,
        email: null
      }
    }
  },
  props: {
    activeModalEditProfile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(['getMyProfileData', 'passwordUpdate', 'validateSession']),
    async getMyProfileAction () {
      const resp = await this.getMyProfileData()
      if (resp) {
        this.dataAdmin.name = resp.userInfo.first_name
        this.dataAdmin.lastName = resp.userInfo.last_name
        this.dataAdmin.email = resp.userInfo.email
      }
    },
    successfulUpdateProfile () {
      this.$emit('close-modal')
      this.getMyProfileAction()
      this.validateSession()
    },
    shPassword (input) {
      if (input === 'confirm') {
        this.inputTypeConfirm = this.inputTypeConfirm === 'password' ? 'text' : 'password'
      } else if (input === 'current') {
        this.inputTypeCurrent = this.inputTypeCurrent === 'password' ? 'text' : 'password'
      } else {
        this.inputType = this.inputType === 'password' ? 'text' : 'password'
      }
    },
    async changePassword () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          currentPassword: this.currentPassword,
          password: this.password
        }
        const resp = await this.passwordUpdate(data)
        if (resp) {
          this.currentPassword = null
          this.password = null
          this.passwordConfirmation = null
          this.modeEditPassword = false
        }
        this.loading = false
      }
    }
  },
  beforeMount () {
    this.getMyProfileAction()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
  }

  .control.is-fullwidth {
    width: 100%;
  }
  .input {
    border: solid 1px #b5b5b5;
  }
  .input.icon_btn {
    border-top: solid 1px #b5b5b5;
    border-right: none;
    border-left: solid 1px #b5b5b5;
    border-bottom: solid 1px #b5b5b5;
  }
  .button.icon_action {
    border-color: transparent;
    border-top: solid 1px #b5b5b5;
    border-right: solid 1px #b5b5b5;
    border-bottom: solid 1px #b5b5b5;
    background-color: white;
  }
  .button.icon_action:focus {
    box-shadow: none;
  }
  .visibility_icon {
    color: #3b3b3b;
  }
</style>
